<template>
  <div class="mall">
    <!-- 招商加盟 -->
    <kind-choose-views :showKc="false" :type="12" @isOk="isOk" :entrantsType="3" :testPaperType="testPaperType"
      class="kind_choose" v-if="typeOne" />
    <!-- 聚学U -->
    <kind-choose-view :showKc="false" :type="12" @isOk="isOk" :entrantsType="3" :testPaperType="testPaperType"
      class="kind_choose1" v-if="typeTwo" />
    <!-- 大客户 -->
    <kind-choose-views :showKc="false" :type="12" @isOk="isOk" :entrantsType="3" :testPaperType="testPaperType"
      class="kind_choose2" v-if="typeFour" />
    <!-- 高校共建（合伙共建） -->
    <kind-choose-views :showKc="false" :type="12" @isOk="isOk" :entrantsType="3" :testPaperType="testPaperType"
      class="kind_choose3" v-if="typeThree" />
    <!-- 学习资料 -->
    <div class="mall_centent" v-if="list && list.length > 0">
      <div class="centent_item" v-for="(item, index) in list" :key="index">
        <div class="item_left">
          <el-image :src="item.faceImg"></el-image>
        </div>
        <div class="item_right">
          <div class="right_name">{{ item.textbookName }}</div>
          <div class="right_note">
            <div class="note_item" v-for="(item, index) in item.introduce" :key="index" :class="`note_col${index}`">
              {{ item }}
            </div>
          </div>
          <!-- 通过动态绑定class改变按钮样式 -->
          <div class="right_btn" :class="{
            right_btn1: typeOne,
            right_btn2: typeTwo,
            right_btn3: typeThree,
            right_btn4: typeFour,
          }" @click="toMaterialPage(item)">
            查看教材
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div class="item_pagination">
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import KindChooseView from "./components/choose.vue";
import KindChooseViews from "./components/kindChooseView.vue";
import { selectTextbookPcPage,selectTextbookPcJxuPage } from "@/api/mykecheng";
import { getSurfaceList } from "@/api/home";
import { getDomain } from "@/api/cookies";
export default {
  components: { KindChooseView, KindChooseViews },

  data() {
    return {
      bannerList: [],
      list: [],
      search: {},
      pageSize: 10,
      pageNum: 1,
      total: 0,
      testPaperType: null,
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
    };
  },
  async created() {
    this.testPaperType = this.$route.query.type;
    this.getBanner();
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟
     * entrantsType=3   =>  聚学U
     * entrantsType=1   =>  大客户
     * entrantsType=5   =>  高校共建（合伙共建）
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    }
  },
  methods: {
    async getBanner() {
      const res = await getSurfaceList(4, undefined, this.testPaperType);
      this.bannerList = res.msg;
    },
    // 获取知识套餐列表
    getList() {
      if(this.typeTwo){
        selectTextbookPcJxuPage({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          kindId: this.search.kind,
          zhuanyeId: this.search.zyId,
          bigKindId:this.search.zhuanyeId,
          kindStage:this.search.kindStage
          // this.search.stageName,
          // this.search.type,
          // this.userInfo ? this.userInfo.id : undefined,
          // this.search.kcId
        }).then((res) => {
          res.rows.forEach((e) => {
            if (e.introduce && !e.introduce.includes("[")) {
              e.introduce = e.introduce.split(",");
            } else {
              e.introduce = JSON.parse(e.introduce);
            }
          });
          this.list = res.rows;
          this.total = res.total;
          if (res.code) {
            this.list = [];
            this.total = 0;
  
          }
        });
      } else{

        selectTextbookPcPage({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          kindId: this.search.kind,
          zhuanyeId: this.search.zyId,
          bigKindId:this.search.zhuanyeId,
          kindStage:this.search.kindStage
          // this.search.stageName,
          // this.search.type,
          // this.userInfo ? this.userInfo.id : undefined,
          // this.search.kcId
        }).then((res) => {
          res.rows.forEach((e) => {
            if (e.introduce && !e.introduce.includes("[")) {
              e.introduce = e.introduce.split(",");
            } else {
              e.introduce = JSON.parse(e.introduce);
            }
          });
          this.list = res.rows;
          this.total = res.total;
          if (res.code) {
            this.list = [];
            this.total = 0;
  
          }
        });
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {
      this.search = val;
      this.pageNum = 1
      this.getList();
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      this.$router.push(`/mall/material?bookId=${item.id}`);
    },
    /* 点击免费试看 */
    toMaterialPage(item) {
      // if (this.userInfo) {
      this.$router.push({
        path: `/mall/material`,
        query: {
          id: item.id,
          title: item.textbookName,
          isPurchase: item.isPurchase,
        },
      });
      // } else {
      //   this.$notify({
      //     title: '错误',
      //     message: '未登录，请去登录',
      //     type: 'error'
      //   })
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.mall {
  width: 100%;
  padding-bottom: 40px;

  /deep/ .zychoice {
    margin: 20px auto !important;
  }

  /deep/ .kind_choose {
    .zychoice {
      .zychoiceTop {
        .zychoiceTopRight {
          .on {
            background: rgba(255, 93, 81, 0.1) !important;
            border-radius: 4px 4px 4px 4px;
            color: #ff5d51 !important;
          }
        }
      }
    }
  }

  /deep/ .kind_choose1 {
    .zychoice {
      .zychoiceTop {
        .zychoiceTopRight {
          .on {
            background: rgba(0, 171, 140, 0.1) !important;
            border-radius: 4px 4px 4px 4px;
            color: #00ab8c !important;
          }
        }
      }
    }
  }

  /deep/ .kind_choose2 {
    .zychoice {
      .zychoiceTop {
        .zychoiceTopRight {
          .on {
            background: rgba(16, 97, 255, 0.1) !important;
            border-radius: 4px 4px 4px 4px;
            color: #1061ff !important;
          }
        }
      }
    }
  }

  /deep/ .kind_choose3 {
    .zychoice {
      .zychoiceTop {
        .zychoiceTopRight {
          .on {
            background: rgba(30, 130, 245, 0.1) !important;
            border-radius: 4px 4px 4px 4px;
            color: #1e82f5 !important;
          }
        }
      }
    }
  }

  .mall_centent {
    width: 1200px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .centent_item {
      width: 590px;
      height: 220px;
      background: #ffffff;
      border-radius: 10px;
      opacity: 1;
      margin-bottom: 50px;
      padding: 0 20px 0 25px;
      display: flex;
      position: relative;

      .item_left {
        width: 159px;
        height: 215px;
        margin-top: -20px;

        /deep/ .el-image {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .item_right {
        margin: 30px 0 0 31px;

        .right_name {
          width: 348px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          white-space: normal;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .right_note {
          margin-top: 22px;

          .note_item {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #818395;
            display: flex;
            align-items: center;
            margin: 10px 0;
          }

          .note_col0::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #ff5c5d;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-right: 6px;
          }

          .note_col1::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #608cff;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-right: 6px;
            transform: rotate(45deg);
          }

          .note_col2::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #ff8b35;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-right: 6px;
          }
        }

        .right_btn {
          width: 108px;
          height: 36px;
          border-radius: 18px 18px 18px 18px;
          opacity: 1;
          position: absolute;
          right: 20px;
          bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        .right_btn1 {
          background: #ff5d51;
        }

        .right_btn2 {
          background: #00ab8c;
        }

        .right_btn3 {
          background: linear-gradient(90deg, #6cadf8 0%, #1c81f5 100%);
        }

        .right_btn4 {
          background: #1061ff;
        }

        //
      }
    }

    .centent_item:nth-last-child(1) {
      margin-bottom: -15px;
    }

    .centent_item:nth-last-child(2) {
      margin-bottom: -15px;
    }
  }

  .item_pagination {
    width: 1200px;
    height: 50px;
    display: flex;
    margin: -10px auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px !important;
  }

  .nothing {
    padding: 100px 0;
  }
}
</style>
