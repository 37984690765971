<template>
  <!-- 盒子 -->
  <div class="box-div" :class="`entrantsType${entrantsType}`">
    <div class="mall_banner">
      <el-carousel trigger="click" class="banner">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <el-image class="banner_img" :src="item.img"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="zychoice">
      <!-- 选择xueyuan -->
      <div class="zychoiceTop" v-if="showSchool">
        <div class="zychoiceTopLeft">选择学院：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="schoolClick()" :class="[!search.school ? 'on' : '']">
            全部
          </div>
          <div class="right-item" @click="schoolClick(item)" v-for="(item, ina) in schoollist" :key="ina"
            :class="search.school == item.pKind ? 'on' : ''">
            {{ item.pName }}
          </div>
        </div>
      </div>
      <!-- 选择项目 -->
      <div class="zychoiceTop">
        <div class="zychoiceTopLeft">选择项目：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="kindClick()" :class="[!search.kind ? 'on' : '']">
            全部
          </div>

          <div class="right-item" @click="kindClick(item)" v-for="(item, ina) in kindList" :key="ina"
            :class="search.kind == item.pKind ? 'on' : ''">
            {{ item.pName }}
          </div>
        </div>
      </div>
      <!-- 选择层次 -->
      <!-- <div
        class="zychoiceTop"
        v-if="
          search.kind &&
          stageList.length > 0 &&
          showLevel &&
          search.examType != 2
        "
      >
        <div class="zychoiceTopLeft">{{ zStage }}：</div>
        <div class="zychoiceTopRight">
          <div
            class="right-item"
            @click="stageChoose()"
            :class="[search.stage == '全部' ? 'on' : '']"
          >
            全部
          </div>

          <div
            class="right-item"
            @click="stageChoose(item)"
            v-for="(item, inb) in stageList"
            :key="inb"
            :class="[search.stage == item.stage ? 'on' : '']"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->
      <!-- 选择专业 -->
      <div class="zychoiceTop" v-if="search.kind && showZy && search.examType != 2">
        <div class="zychoiceTopLeft">{{ kindZyName }}：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="zyChoose()" :class="[!search.zyId ? 'on' : '']">
            全部
          </div>

          <div class="right-item" @click="zyChoose(item)" v-for="(item, inb) in zylist" :key="inb"
            :class="[search.zyId == item.zId ? 'on' : '']">
            {{ item.zName }}
          </div>
        </div>
      </div>
      <!-- 选择省份 -->
      <!-- <div class="zychoiceTop" v-if="search.zyId && search.examType != 2">
        <div class="zychoiceTopLeft">选择省份：</div>
        <div class="zychoiceTopRight">
          <div
            class="right-item"
            @click="provinceChoose()"
            :class="[!search.areaId ? 'on' : '']"
          >
            全部
          </div>

          <div
            class="right-item"
            @click="provinceChoose(item)"
            v-for="(item, inb) in provinceList"
            :key="inb"
            :class="[search.areaId == item.lanmuID ? 'on' : '']"
          >
            {{ item.lname }}
          </div>
        </div>
      </div> -->
      <!-- 专业课程 -->
      <div class="zychoiceTop" v-if="search.areaId && showKc">
        <div class="zychoiceTopLeft">全部课程：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="kcChoose()" :class="[!search.kcId ? 'on' : '']">
            全部
          </div>
          <div class="right-item" @click="kcChoose(item)" v-for="(item, index) in kcList" :key="index"
            :class="[search.kcId == item.id ? 'on' : '']">
            {{ item.kcName }}
          </div>
        </div>
      </div>
      <!-- 班型 -->
      <div class="zychoiceTop" v-if="type == 15 && classesFenlei.length > 0">
        <div class="zychoiceTopLeft">选择类型：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="classTypeChoose()" :class="[search.productLine == 4 ? 'on' : '']">
            全部
          </div>
          <div class="right-item" v-for="item in classTypeList" :key="item.value"
            :class="search.productLine == item.value ? 'on' : ''" @click="classTypeChoose(item)"
            v-show="classesFenlei.includes(item.value)">
            {{ item.label }}
          </div>
        </div>
      </div>
      <!-- 课程 -->
      <div class="zychoiceTop" v-if="type == 117 && classesFenlei.length > 0">
        <div class="zychoiceTopLeft">选择类型：</div>
        <div class="zychoiceTopRight">
          <div class="right-item" @click="courseTypeChoose()" :class="[search.productLine == 4 ? 'on' : '']">
            全部
          </div>
          <div class="right-item" v-for="item in courseTypeList" :key="item.value"
            :class="search.productLine == item.value ? 'on' : ''" @click="courseTypeChoose(item)"
            v-show="classesFenlei.includes(item.value)">
            {{ item.label }}
          </div>
        </div>
      </div>
      <!-- 试卷/试卷包 -->
      <!-- <div
        class="zychoiceTop"
        v-if="(type == 7 || type == 1 || type == 12) && entrantsType == 3"
        v-show="classfiList.length > 0"
      >
        <div class="zychoiceTopLeft">选择分类：</div>
        <div class="zychoiceTopRight">
          <div
            class="right-item"
            @click="clas()"
            :class="[!search.type ? 'on' : '']"
          >
            全部
          </div>
          <div
            class="right-item"
            v-for="(item, index) in classfiList"
            :key="index"
            :class="[search.type == item.fId ? 'on' : '']"
            v-show="item.fName != '随堂练习'"
            @click="clas(item)"
          >
            {{ item.fName }}
          </div>
        </div>
      </div> -->
      <div class="zychoiceTop" v-if="type == 7 && entrantsType == 3 && (testPaperPack || testPaper)">
        <div class="zychoiceTopLeft">选择类型：</div>
        <div class="zychoiceTopRight">
          <div v-if="testPaperPack" class="right-item" @click="exam(1)" :class="[search.examType == 1 ? 'on' : '']">
            试卷包
          </div>
          <div v-if="testPaper" class="right-item" @click="exam(2)" :class="[search.examType == 2 ? 'on' : '']">
            试卷
          </div>
        </div>
      </div>
    </div>
    <!-- 试卷/试卷包 -->
    <div class="tabBox dis_flex_between m-t-40" v-if="(type == 7 || type == 1 || type == 12) && entrantsType == 2">
      <div class="list" v-show="classfiList.length > 0">
        <div class="item" :class="[!search.type ? 'on' : '']">
          <div class="item-title" @click="clas()">全部</div>
        </div>
        <div v-for="(item, index) in classfiList" :key="index" class="dis_flex item"
          :class="[search.type == item.fId ? 'on' : '']" v-show="item.fName != '随堂练习'">
          <!-- v-if="index > 0" -->
          <el-divider direction="vertical"></el-divider>
          <div class="item-title" @click="clas(item)">{{ item.fName }}</div>
        </div>
      </div>
      <el-radio-group v-if="type == 7 && (testPaperPack || testPaper)" class="redRadio fles-shrink"
        v-model="search.examType" @change="changeList">
        <el-radio :label="1" v-if="testPaperPack">试卷包</el-radio>
        <el-radio :label="2" v-if="testPaper">试卷</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  getTenantidNavigation,
  selectKindAllCollage,
  selectKindIdList,
  selectZyIdList,
  selectZyList,
  getSurfaceList,
  selectProvinceVoList,
} from "@/api/home";
import { Know, getClassesFenlei, getCourseFenLei } from "@/api/know";
let know = new Know();
import { Classifi } from "@/api/classifi";
let classifi = new Classifi();
export default {
  props: {
    entrantsType: {
      /*entrantsType { name: "同行转型", key: 1 },
      { name: "招商加盟", key: 2 },
      { name: "高校合伙人", key: 3 } */
      default: 2,
      type: Number,
    },
    showSchool: {
      default: true,
      type: Boolean,
    },
    kind: {
      type: Number,
    },
    zyId: {
      type: Number,
    },
    schoole: {
      type: Number,
    },

    showZy: {
      type: Boolean,
      default: true,
    },
    showKc: {
      type: Boolean,
      default: false,
    },
    type: {
      default: 1,
      type: Number,
    },
    ifImg: {
      type: Boolean,
      default: true,
    },
    showLevel: {
      type: Boolean,
      default: true,
    },
    testPaperType: {
      type: String,
    },
  },
  data() {
    return {
      imgList: [],
      search: {
        school: null,
        schoolName: null,
        type: null,
        /*   合作伙伴分类 = 1,
    自考专业分类 = 5,
    试卷类型 = 7,
    普通专升本专业分类 = 9,
    学位类别 = 10,
    课程性质 = 11,
    资料分类 = 12,
    课件分类 = 13,
    辅导资料类型 = 14,
    知识套餐类型 = 15 */
        examType: 1,
        kind: null,
        stage: "全部",
        zyId: null,
        kcId: null,
        kindName: null,
        stageName: null,
        zyName: null,
        kcName: null,
        productLine: 4, // 精品/普通班型
        kcType: null, // 精品/普通课程
        areaId: null,
      },
      schoollist: [],
      kindList: [],
      stageList: [],
      Kindzylist: [], //当前项目下的专业
      zylist: [],
      kcList: [],
      classfiList: [], //类型
      courseTypeList: [
        { label: "基础课程", value: "1" },
        { label: "精品课程", value: "2" },
      ], // 类型筛选 精品、普通课程
      classTypeList: [
        { label: "基础班型", value: "1" },
        { label: "精品班型", value: "2" },
      ],
      kindZyName: "全部专业",
      zStage: "全部层次",

      testPaperPack: false,
      testPaper: false,

      classesFenlei: [],
      provinceList: [], // 省份列表
    };
  },
  async created() {
    if (this.testPaperType) {
      this.search.type = this.testPaperType;
    }
    getSurfaceList(8, undefined, this.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg;
      }
    });
    await this.getKind();
    /*  await this.getZylist(); */
    if (this.type == 115) {
      /* 公开课类型 */
      let coursetype = [
        { fName: "公开课", fId: 0 },
        { fName: "导学课", fId: 1 },
        { fName: "试听课", fId: 2 },
      ];
      this.classfiList = coursetype;
    } else if (this.type == 116) {
      /* 公开课类型 */
      let coursetype = [
        { fName: "串讲课", fId: 1 },
        { fName: "精讲课", fId: 2 },
        { fName: "冲刺课", fId: 3 },
      ];
      this.classfiList = coursetype;
    } else if (this.type == 117) {
      /* 虚拟考场类型 */
      let coursetype = [
        { fName: "公开", fId: 1 },
        { fName: "限邀请", fId: 2 },
      ];
      this.classfiList = coursetype;
      this.getCourseFenLei();
    } else if (this.type == 1 || this.type == 12) {
      this.getMaterialClass();
    } else if (this.type == 7) {
      /* this.getTabList(); */

      /* 试卷包分类 */
      classifi.getPackageFenlei().then((res) => {
        if (res.data.length > 0) {
          this.testPaperPack = true;
        }
        this.classfiList = res.data.filter((item, index) => {
          return item.fName != "随堂练习";
        });
        // this.search.type = this.classfiList[0].fId;
        this.getLIst();
      });
      /* 试卷分类 */
      classifi.getPaperFenlei().then((res) => {
        if (res.data.length > 0) {
          this.testPaper = true;
        }
        // this.search.type = this.classfiList[0].fId;
      });
    } else if (this.type == 15) {
      this.getClassesFenlei();
    }
    if (this.schoole) {
      for (let item of this.schoollist) {
        if (item.pKind == this.schoole) {
          this.schoolClick(item);
          break;
        }
      }
    }
    if (this.kind) {
      for (let item of this.kindList) {
        if (item.pKind == this.kind) {
          this.kindClick(item);
          break;
        }
      }
    }
    if (this.zyId) {
      for (let item of this.zylist) {
        if (item.zId == this.zyId) {
          this.zyChoose(item);
          break;
        }
      }
    }
  },
  methods: {
    /* 获取分类列表加载下拉 */
    /* getTabList() {
        classifi.getClassSelectSelect(this.type).then((res) => {
          this.classfiList = res.filter((item, index) => {
            return item.fName != "随堂练习";
          });
          // this.search.type = this.classfiList[0].fId;
          this.getLIst();
        });
      }, */
    /* 资料包分类 */
    getMaterialClass() {
      classifi.getDocPackageFenlei().then((res) => {
        this.classfiList = res.data.filter((item, index) => {
          return item.fName != "随堂练习";
        });
        // this.search.type = this.classfiList[0].fId;
        this.getLIst();
      });
    },
    getTabList() {
      if (this.search.examType == 1) {
        /* 试卷包分类 */
        classifi.getPackageFenlei().then((res) => {
          this.classfiList = res.data.filter((item, index) => {
            return item.fName != "随堂练习";
          });
          // this.search.type = this.classfiList[0].fId;
          this.getLIst();
        });
      } else {
        /* 试卷分类 */
        classifi.getPaperFenlei().then((res) => {
          this.classfiList = res.data.filter((item, index) => {
            return item.fName != "随堂练习";
          });
          // this.search.type = this.classfiList[0].fId;
          this.getLIst();
        });
      }
    },
    // 班型分类
    getClassesFenlei() {
      getClassesFenlei().then((res) => {
        res.msg.splice(res.msg.indexOf("3"), 1);
        this.classesFenlei = res.msg;
      });
    },
    // 精品课程分类
    getCourseFenLei() {
      getCourseFenLei().then((res) => {
        this.classesFenlei = res.msg;
      });
    },
    /* 学院  */
    schoolClick(item) {
      if (item) {
        this.search.school = item.pKind;
        this.search.schoolName = item.pName;
        this.kindList = item.kindList;
        this.search.kind = null;
        this.search.kindName = null;
        /*  this.getZylist(); */
        this.search.zyId = "";
        this.search.zyName = "";
        this.search.kcId = "";
        this.search.kcName = "";
        this.kindZyName = "全部专业";
        this.zStage = "全部层次";
        this.search.zhuanyeId = item.pKind;
      } else {
        this.search.school = "";
        this.search.schoolName = "";
        this.getKind();
        this.search.kind = "";
        /*  this.getZylist(); */
        this.search.kindName = "";
        this.search.zyId = "";
        this.search.zyName = "";
        this.search.kcId = "";
        this.search.kcName = "";
        this.zStage = "全部层次";
        this.kindZyName = "全部专业";
        this.search.zhuanyeId = "";
      }
      this.getLIst();
    },
    /* 获取项目列表 */
    getKind() {
      if (this.showSchool) {
        selectKindIdList().then((res) => {
          if (res.code == 0) {
            this.schoollist = res.data.PKindVoList;
            this.kindList = res.data.pKindList
          }
        });
      }
    },
    /* 项目选择 */
    async kindClick(item) {
      if (item) {
        this.search.kind = item.pKind;
        this.search.kindName = item.pName;

        this.stageList = item.pStage ? JSON.parse(item.pStage) : [];
        this.search.zyId = "";
        this.search.zyName = "";
        this.search.stage = "全部";
        this.search.stageName = "";
        this.search.kcId = "";
        this.search.kcName = "";
        Vue.prototype.kindZy(item.pKind, "stage").then((res) => {
          this.kindZyName = res.zName ? res.zName : "全部专业";
          this.zStage = res.zStage ? res.zStage : "全部层次";

          this.$forceUpdate();
        });
        const res = await selectZyIdList({
          bigKindId: item.pParentid,
          kindId: item.pKind
        })
        this.zylist = res.data
        // if (this.type == 12) {
        //   classifi.selectZhuanyeByKind(item.pKind, "").then((res) => {
        //     this.zylist = res.msg;
        //     this.Kindzylist = res.msg;
        //   });
        // } else if (this.type == 7 && this.search.examType == 1) {
        //   classifi.selectZhuanyeByKind(item.pKind).then((res) => {
        //     this.zylist = res.data;
        //     this.Kindzylist = res.data;
        //   });
        // } else if (this.type == 7 && this.search.examType == 2) {
        //   classifi.selectZhuanyeByKind(item.pKind).then((res) => {
        //     this.zylist = res.data;
        //     this.Kindzylist = res.data;
        //   });
        // } else {
        //   this.zylist = item.zyList;
        //   this.Kindzylist = item.zyList;
        // }
      } else {
        this.search.kind = "";
        /*  this.getZylist(); */
        this.zylist = [];
        this.Kindzylist = [];
        this.stageList = [];
        this.search.kindName = "";
        this.search.zyId = "";
        this.search.zyName = "";
        this.search.stage = "全部";
        this.search.stageName = "";
        this.search.kcId = "";
        this.search.kcName = "";
        this.zStage = "全部层次";
        this.kindZyName = "全部专业";
      }
      this.getKc();
      this.getLIst();
    },
    /* 层次选择 */
    // stageChoose(item) {
    //   if (item) {
    //     this.search.stage = item.stage;
    //     this.search.stageName = item.name;
    //     this.search.zyId = "";
    //     this.search.zyName = "";
    //     this.search.kcId = "";
    //     this.search.kcName = "";
    //     if (this.type == 12) {
    //       classifi
    //         .selectZhuanyeByKind(this.search.kind, item.name)
    //         .then((res) => {
    //           this.zylist = res.msg;
    //         });
    //     } else if (this.type == 7 && this.search.examType == 1) {
    //       classifi
    //         .selectZhuanyeByKind(this.search.kind, item.name)
    //         .then((res) => {
    //           this.zylist = res.msg;
    //         });
    //     } else if (this.type == 7 && this.search.examType == 2) {
    //       classifi
    //         .selectZhuanyeByKind(this.search.kind, item.name)
    //         .then((res) => {
    //           this.zylist = res.msg;
    //         });
    //     } else {
    //       this.zylist = this.Kindzylist.filter((zy) => {
    //         return zy.zStage == item.name;
    //       });
    //     }
    //   } else {
    //     if (this.type == 12) {
    //       classifi.selectZhuanyeByKind(this.search.kind, "").then((res) => {
    //         this.zylist = res.msg;
    //       });
    //     } else if (this.type == 7 && this.search.examType == 1) {
    //       classifi.selectZhuanyeByKind(this.search.kind, "").then((res) => {
    //         this.zylist = res.msg;
    //       });
    //     } else if (this.type == 7 && this.search.examType == 2) {
    //       classifi.selectZhuanyeByKind(this.search.kind, "").then((res) => {
    //         this.zylist = res.msg;
    //       });
    //     } else {
    //       this.zylist = this.Kindzylist;
    //     }
    //     this.search.stage = "全部";
    //     this.search.stageName = "";
    //     this.search.zyId = "";
    //     this.search.zyName = "";
    //     this.search.kcId = "";
    //     this.search.kcName = "";
    //   }
    // },
    /* 专业选择 */
    zyChoose(item) {
      if (item) {
        this.search.zyId = item.zId;
        this.search.zyName = item.zName;
        this.search.kcId = "";
        this.search.kcName = "";
        if (this.showKc) {
          this.getKc();
        }
        this.selectProvinceVoList();
      } else {
        this.search.zyId = "";
        this.search.zyName = "";
        this.search.kcId = "";
        this.search.kcName = "";
      }
      this.getLIst();
    },
    /* 省份列表 */
    selectProvinceVoList() {
      let data = {
        zyId: this.search.zyId,
        isPass: 1,
      };
      selectProvinceVoList(data).then((res) => {
        this.provinceList = res.data;
      });
    },
    // 省份选择
    provinceChoose(item) {
      if (item) {
        this.search.areaId = item.lanmuID;
        if (this.showKc) {
          this.getKc();
        }
      } else {
        this.search.areaId = "";
      }
      this.getLIst();
    },
    /* 专业列表 */
    async getZylist() {
      await selectZyList(
        this.search.school ? this.search.school : undefined,
        this.search.kind ? this.search.kind : undefined
      ).then((res) => {
        this.zylist = res.data;
      });
    },
    getKc() {
      if (this.type == 12) {
        classifi
          .getDocPackageCourse(this.search.kind, this.search.zyId)
          .then((res) => {
            for (let item of res.data) {
              item.kid = item.id;
              item.keName = item.kcName;
            }
            this.kcList = res.data;
          });
      } else if (this.type == 7 && this.search.examType == 1) {
        classifi
          .getPackageCourse(this.search.kind, this.search.zyId)
          .then((res) => {
            for (let item of res.data) {
              item.kid = item.id;
              item.keName = item.kcName;
            }
            this.kcList = res.data;
          });
      } else if (this.type == 7 && this.search.examType == 2) {
        classifi
          .getPaperCourse(this.search.kind, this.search.zyId)
          .then((res) => {
            for (let item of res.data) {
              item.kid = item.id;
              item.keName = item.kcName;
            }
            this.kcList = res.data;
          });
      } else {
        /* know.zhuanyekecheng(undefined, this.search.zyId).then((res) => {
            if (res.code == 0) {
              this.kcList = res.msg;
            } else {
              this.kcList = [];
            }
          }); */

        /* know.getKindZidList(this.search.kind, this.search.zyId).then(res => {
            if (res.code == 0) {
              this.kcList = res.data;
            } else {
              this.kcList = [];
            }
          }) */

        know
          .selectProvinceKechengList(this.search.zyId, this.search.areaId)
          .then((res) => {
            if (res.code == 0) {
              this.kcList = res.data;
            } else {
              this.kcList = [];
            }
          });
      }
    },
    /* 课程选择 */
    kcChoose(item) {
      if (item) {
        this.search.kcId = item.id;
        this.search.kcName = item.kcName;
      } else {
        this.search.kcId = "";
        this.search.kcName = "";
      }
      this.getLIst();
    },
    /* 选择所属类型 */
    clas(val) {
      if (val) {
        this.search.type = val.fId;
      } else {
        this.search.type = null;
      }
      this.getLIst();
    },
    /* 选择试卷包、试卷 */
    exam(val) {
      this.search.examType = val;
      this.changeList();
      // this.getTabList();
    },
    /* 班型 精品/普通 */
    classTypeChoose(val) {
      if (val) {
        this.search.productLine = val.value;
      } else {
        this.search.productLine = 4;
      }
      this.getLIst();
    },
    /* 课程  精品/普通 */
    courseTypeChoose(val) {
      if (val) {
        this.search.productLine = val.value;
      } else {
        this.search.productLine = 4;
      }
      this.getLIst();
    },
    /* 清空搜索 */
    changeList() {
      this.search.school = null;
      this.search.schoolName = null;
      this.search.type = null;
      this.search.kind = null;
      this.search.stage = "全部";
      this.search.zyId = null;
      this.search.kcId = null;
      this.search.kindName = null;
      this.search.stageName = null;
      this.search.zyName = null;
      this.search.kcName = null;
      this.search.productLine = 4; // 精品/普通班型
      this.search.kcType = null; // 精品/普通课程
      this.getTabList();
      // this.getLIst();
    },
    getLIst() {
      this.$nextTick(() => {
        this.$emit("isOk", this.search);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/* 高校合伙人 */
.entrantsType3 {
  .zychoice {
    width: 1200px !important;
    background: #ffffff;
    border-radius: 10px;
    opacity: 1;
    margin: 40px auto 24px;
    padding: 0px 32px 20px;
    font-size: 14px;

    .on {
      background: #f1f4ff !important;
      color: #4a6af0 !important;
    }
  }
}

.box-div {
  width: 100%;
  margin: 0px auto 0px;

  .mall_banner {
    width: 1200px;
    margin: 20px auto;
    height: 230px;
    background-color: #333;
    border-radius: 10px;

    .banner {
      width: 100%;
      height: 100%;
      border-radius: 10px !important;

      /deep/ .el-carousel__container {
        height: 100%;
      }

      /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
        width: 4px;
        height: 4px;
        background: #ffffff;
        border-radius: 50%;
      }

      /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
        width: 16px;
        height: 4px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
      }

      .banner_img {
        width: 100%;
        height: 100%;
        border-radius: 10px !important;
      }
    }
  }
}

.tui-img {
  width: 100%;
  height: 240px;
}

.zychoice {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 6px 0px rgba(216, 216, 216, 0.7);
  background: #ffffff;
  overflow: hidden;
  padding: 0px calc(50% - 610px) 32px;
  border-radius: 10px;

  .zychoicePzion {
    width: 100%;
    background: #ffffff;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .el-breadcrumb {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .zychoiceTop {
    width: 100%;
    background: #ffffff;
    padding-top: 20px;
    // height: 70px;
    height: auto;
    // border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;

    &:nth-child(1) {
      .right-item {
        font-size: 14px !important;
      }
    }

    .zychoiceTopLeft {
      flex-shrink: 0;
    }

    .zychoiceTopRight {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .right-item {
        height: 30px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        padding: 0px 16px;
        cursor: pointer;
      }

      .on {
        background: #f5f6f6;
        border-radius: 4px 4px 4px 4px;
        color: #ff5d51;
      }
    }
  }

  .zychoiceCen {
    width: 90%;
    height: 64px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .spana {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 53px;
      cursor: pointer;
    }

    .spanb {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 40px;
      cursor: pointer;
    }

    .spanbs {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff4027;
      margin-right: 40px;
      cursor: pointer;
    }

    .spanc {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin-right: 30px;
      cursor: pointer;
    }

    .spancs {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff4027;
      margin-right: 30px;
      cursor: pointer;
    }
  }
}

.tabBox {
  width: 1220px;
  margin: auto;
  margin-top: 40px;

  .list {
    display: flex;

    .item {
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 14px;

      .el-divider {
        margin: 0px 24px;
      }
    }

    .on {
      color: #ff5e51 !important;
    }
  }
}
</style>
